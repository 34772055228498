.searchbar-container {
  width: 70%;
  margin: 5% 0;
  font-weight: bolder;

  .title {
    font-size: 2rem;
    font-weight: bolder;
    text-align: center;
    color: whitesmoke;
  }

  .searchbar {
    font-size: x-large;
  }
}
