.toggle-container {
  display: flex;
  flex-direction: row;
  margin-top: 5%;
  font-size: 1.25rem;

  .nav-tabs {
    border-bottom: none;
  }

  .nav-tabs .nav-link {
    font-weight: bolder;
  }

  li:nth-child(1) button {
    color: seagreen;
  }

  li:nth-child(2) button {
    color: brown;
  }
}
