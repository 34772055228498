.header {
  display: flex;
  justify-content: flex-end;
  background-color: black;
  padding-top: 1%;

  .nav-link {
    margin-right: 1%;
  }

  a {
    color: white;
  }

  .sign-out {
    height: 30px;
    width: 30px;
    margin-right: 1%;
    background-image: url('../../Assets/Images/logoutIcon.png');
    background-size: 100%;
    background-repeat: no-repeat;
    background-color: black;
    border: none;
    text-decoration: none;
  }
}
