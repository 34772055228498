.sign-in-and-sign-up {
  background-color: black;
  height: 100vh;

  .main-title {
    text-align: center;
    font-size: 12rem;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    font-weight: normal;
    letter-spacing: 2rem;
    color: #212529;
    text-shadow: 2px 2px royalblue;
  }

  .form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 3% 30%;

    .sign-in,
    .sign-up {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 80%;
      padding: 5% 0% 2% 0%;
      color: white;

      button {
        letter-spacing: 0.5px;
        background-color: black;
        color: white;
        font-family: 'Open Sans Condensed';
        font-weight: bolder;
        border: none;

        &:hover {
          background-color: white;
          color: black;
          // border: 1px solid black;
        }
      }

      span {
        width: 50%;
        margin: 3% 0;
        overflow: hidden;
        text-align: center;
        font-weight: 300;
        color: white;
      }

      span:before,
      span:after {
        content: '';
        display: inline-block;
        width: 50%;
        margin: 0 0.5em 0 -55%;
        vertical-align: middle;
        border-bottom: 1px solid;
      }
      span:after {
        margin: 0 -55% 0 0.5em;
      }

      form {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 80%;

        .form-input {
          width: 20rem;
          margin: 5% 5%;
          font-size: 200rem;
        }

        .reset-password {
          font-size: 0.75em;
          align-self: flex-end;
          white-space: nowrap;

          &:hover {
            cursor: pointer;
            text-decoration: underline;
          }
        }
      }
    }
    .redirect-sign-up {
      display: flex;
      color: white;
      white-space: nowrap;

      .redirect-link {
        color: royalblue;
        margin-right: 0.5em;

        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }

      p {
        font-size: 0.75em;
      }
    }
  }
}
