.toolbar-container {
  display: flex;
  flex-direction: column;
  width: 20%;
  margin-left: 2%;
  text-align: center;

  .toolbar-header {
    font-size: 1.75rem;
    color: white;
    margin-bottom: 5%;
    font-weight: bolder;
  }

  .category-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 8rem;
    margin-bottom: 25%;
  }
}
