.reset-password-page {
  display: flex;
  justify-content: center;
  background-color: black;
  height: 100vh;

  .main-container {
    width: 40%;
    margin: 20% 0 5% 0;
    text-align: center;
    color: white;
    font-size: 1em;

    h3 {
      color: white;
      font-size: 1.25em;
    }

    .form-container {
      display: flex;
      justify-content: center;

      .email-reset-form {
        margin: 3% 0 5% 0;
      }

      button {
        background-color: black;
      }
    }
    .link-back {
      color: white;
      font-size: 0.75em;
      margin: 3% 0;
      text-decoration: underline;

      &:hover {
        cursor: pointer;
      }
    }
  }
}
