* {
  list-style: none;
  text-decoration: none;
  margin: 0;
}
body {
  background-color: black;
}
.main-container {
  display: flex;
  flex-direction: column;
  height: 100vh;

  .main-title {
    text-align: center;
    font-size: 12rem;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    font-weight: normal;
    letter-spacing: 2rem;
    color: #212529;
    text-shadow: 2px 2px royalblue;
  }

  .inner-container {
    display: flex;
    flex-direction: row;
    justify-content: left;
    width: 100%;

    .attribute-selection {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 80%;

      .name-form {
        margin-top: 7%;
        width: 40%;
      }

      .manage-title {
        font-size: 3rem;
        text-align: center;
        font-weight: bolder;
        color: white;
      }
    }
    .selected-container {
      display: flex;
      flex-direction: column;
      margin-right: 2%;
      margin-bottom: 5%;
      cursor: pointer;

      .selected-list {
        margin-top: 15%;
      }
    }
  }

  .results-window {
    display: none;
  }
}
